import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormInput } from 'components/index';
import { BOOKING_DEPOSIT } from 'config';

class PaymentCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
    };
  }

  render() {
    const { card, onSubmit } = this.props;
    const { showInput } = this.state;
    return (
      <div className="c-payment-card u-card">
        <h4 className="c-heading c-heading--h4">{card.card_type}</h4>
        <h4 className="c-heading c-heading--h4">{card.masked_number}</h4>
        <ul className="u-reset-list c-payment-card__details">
          <li>Card Holder: {card.holder_name}</li>
          <li>EXP: {card.expired_date}</li>
        </ul>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            const FORM_DATA = new FormData(e.currentTarget);
            onSubmit(FORM_DATA.get('PartialAmount'), card.id);
          }}
        >
          <button
            type="submit"
            className="c-button c-button--primary"
            onClick={(e) => {
              if (!showInput) {
                e.preventDefault();
                this.setState({
                  showInput: true,
                });
              }
            }}
          >
            Pay with this card
          </button>
          <FormInput
            id="PartialAmount"
            name="PartialAmount"
            type="number"
            placeholder="Enter what you would like to pay"
            modifier={showInput ? '' : 'is-hidden'}
            required
            attrs={{
              min: BOOKING_DEPOSIT,
            }}
          />
        </form>
      </div>
    );
  }
}

PaymentCard.propTypes = {
  card: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PaymentCard;
