import React from 'react';

const BookHaka = () => (
  <div className="c-book-haka">
    <h3 className="c-heading c-heading--h3 c-book-haka__title">WHY CHOOSE HAKA?</h3>
    <ul>
      <li>
        #1 Rated: We’ve got a 5 star rating on TripAdvisor, and 4.9 stars on Google and Facebook - check us out!
      </li>
      <li>
        Small Groups: Bigger is not better. With 16 passengers (max) on your tour, we promise you a superior NZ experience.
      </li>
      <li>
        Expert Kiwi Guides: Our guides live and love everything New Zealand. We’re 100% Kiwi owned and operated too.
      </li>
      <li>
        Pre-book Activities: We are the only NZ tour company that enables you to pre-book activities before your tour starts so you don’t miss out.
      </li>
      <li>
        Flexi-Guarantee: If you are more than 45 days out you can swap onto a different departure or change your tour completely, free of charge.
      </li>
      <li>
        $99 Lifetime Deposits: If you can’t travel it means your deposit isn’t lost, simply saved for your next Haka adventure.
      </li>
    </ul>
  </div>
);

export default BookHaka;
