import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';

import { STATES } from 'config';
import { numberToCurrency, getImageUrl } from 'utilities';
import { calculatePriceWithRate } from 'utilities/countryPriceRate';
import SVGArrow from '../../../public/svg/sub-nav-arrow.svg';

const germanFlag = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
       <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
         "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
       <svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 5 3">
         <desc>Flag of Germany</desc>
         <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000"/>
         <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00"/>
         <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
       </svg>`;

const TourSubNavL2 = ({
  list,
  title,
  isActive,
  onBackClick,
  onMenuClick,
  rate,
}) => (
  <StaticQuery
    query={graphql`
      query {
        allTourImages {
          edges {
            node {
              api_id
              imgix_url
            }
          }
        }
      }
    `}
    render={({ allTourImages }) => (
      <div className={`c-tour-sub-nav-l2 ${isActive ? STATES.ACTIVE : ''}`}>
        <button
          type="button"
          className="c-sub-nav__back"
          onClick={onBackClick}
        >
          <SVGArrow />
          Back
        </button>

        {title && (
          <div className="c-sub-nav__title">
            {title}
          </div>
        )}

        <ul className="c-tour-sub-nav-l2__list">
          {list.sort((a, b) => parseInt(b.number_of_days, 10) - parseInt(a.number_of_days, 10)).map(item => (
            <li
              key={item.id}
              className="c-tour-sub-nav-l2__item"
            >
              <Link
                to={`${item.absolute_slug}/`}
                className="c-tour-sub-nav-l2__link"
                onClick={onMenuClick}
              >
                <div className="c-tour-sub-nav-l2__link-title-desktop">
                  {item.name}
                  {item.is_german_tour && (
                    <span
                      className="c-tour-sub-nav-l2__link-title-desktop-icon-flag"
                      data-tip
                      data-for="germanTour"
                      dangerouslySetInnerHTML={{ __html: germanFlag }}
                    />
                  )}
                </div>

                <div className="c-tour-sub-nav-l2__link-title-mobile">
                  {`${item.number_of_days} Day ${item.name}`}
                  {item.is_german_tour && (
                    <span
                      className="c-tour-sub-nav-l2__link-title-mobile-icon-flag"
                      data-tip
                      data-for="germanTour"
                      dangerouslySetInnerHTML={{ __html: germanFlag }}
                    />
                  )}
                </div>

                <div className="c-tour-sub-nav-l2__link-duration">
                  {`${item.number_of_days} Days`}
                </div>

                <div className="c-tour-sub-nav-l2__link-price">
                  {`From ${numberToCurrency(calculatePriceWithRate(item.starting_price, rate))}`}
                </div>

                <div className="c-tour-sub-nav-l2__link-map">
                  {item.small_map_id && (
                    <img
                      src={getImageUrl({
                        id: item.small_map_id,
                        images: allTourImages.edges,
                        // width: 225,
                        height: 290,
                      })}
                      alt={`${item.name} map`}
                    />
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  />
);

TourSubNavL2.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onBackClick: PropTypes.func,
  onMenuClick: PropTypes.func,
  rate: PropTypes.number,
};

TourSubNavL2.defaultProps = {
  title: null,
  isActive: false,
  onBackClick: () => {},
  onMenuClick: () => {},
  rate: 0,
};

export default TourSubNavL2;
